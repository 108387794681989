<!--suppress JSCheckFunctionSignatures -->
<template>
    <div id="trulioo-sdk" v-if="!finish"></div>
    <div class="docv-finish" v-else>
        <h1>Verification complete.</h1>
    </div>
</template>

<script>
import {useRoute} from 'vue-router'
import {Trulioo, event} from "@trulioo/docv";
import '@trulioo/docv/style.css';

export default {
    name: 'HomeView',
    components: {},
    data() {
        return {
            finish: false
        };
    },
    mounted() {
        if(this.finish) return;

        const route = useRoute();

        let shortCode = route.query.shortCode;
        let locale = route.query.locale;
        let redirectUrl = route.query.redirectUrl;

        console.log(shortCode, locale, redirectUrl);

        // Setup the workflow configuration
        const workflowOption = Trulioo.workflow()
            .setShortCode(shortCode)
            .setLanguage(locale)
            .setRedirectUrl(redirectUrl)
            .setWebView(true)

        // Setup callbacks to get results and debugging errors
        const callbacks = new event.adapters.ListenerCallback({
            onComplete: (success) => {
                console.info(`Verification Successful: ${success.transactionId}`)
                this.finish = true;
            },
            onError: (error) => {
                console.error('Error:', `Verification Failed with Error Code: ${error.code}, TransactionID: ${error.transactionId}, Reason: ${error.message}`)
                this.finish = true;
            },
            onException: (exception) => {
                console.error('Error:', "Verification Failed with Exception:", exception)
                this.finish = true;
            }
        })

        const callbackOption = Trulioo.event().setCallbacks(callbacks)

        // Initialize the SDK with the workflow configuration
        Trulioo.initialize(workflowOption)
            .then(complete => {
                console.info("Initialize complete:", complete)
                // Launch the UI with the provided HTML element ID
                Trulioo.launch("trulioo-sdk", callbackOption)
                    .then(success => {
                        console.info("Launch success:", success)
                    })
            })
            .catch(error =>
                console.error("Error:", error)
            )
    }
}
</script>

<style>

.docv-finish {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    text-align: center;
}

.docv-finish h1 {
    color: black;
    font-weight: 600;
    font-size: 1.5em;
}
</style>